


































































import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { Component, Watch } from "vue-property-decorator";

import api from "@/core/utils/api";
import { Rating, Seminar, Submission } from "@/core/models";
import VideoPlayer from "@/components/common/VideoPlayer.vue";
import VideoRatings from "../components/VideoRatings.vue";
import VideoSubmission from "../components/VideoSubmission.vue";
import VideoMenu from "../components/VideoMenu.vue";

type Video = {
  sub: Submission;
  feedbackFor?: Submission;
  userName: string;
  elementName: string;
  seminarName: string;
};
@Component({
  components: { VideoPlayer, VideoRatings, VideoMenu, VideoSubmission },
})
export default class SubmissionDetails extends Vue {
  @Getter("profile/id") userId!: string;
  @Getter("seminars/selected") seminar?: Seminar;
  @Action("seminars/get") getSeminar!: (id: number) => Promise<void>;

  loading = false;
  notFound = false;
  id: number | null = null;
  video: Video | null = null;

  async getSubmission() {
    // no id
    if (!this.id) {
      this.notFound = true;
      return;
    }

    // load submission
    this.notFound = false;
    this.loading = true;
    try {
      const end = `/api/Submissions/${this.id}`;
      const res = (await api.get(end)) as Video | null;
      if (!res) this.notFound = true;
      else {
        this.video = res;
        this.getSeminar(res.sub.seminarId);
      }
    } catch (error) {
      this.notFound = true;
      console.log(error);
    }
    this.loading = false;
  }

  handleRated({ submissionId, type }: { submissionId: number; type: string }) {
    if (!this.video) return;

    const all: Rating[] = (this.video.sub as any).videoRatings || [];
    const existing = all.find(x => x.rater === this.userId && x.type === type);
    let ratings: Rating[] = [];

    if (!existing)
      ratings = [
        ...all,
        {
          id: !all.length ? 0 : all[all.length - 1].id + 1,
          submissionId,
          type,
          rater: this.userId,
          createdAt: new Date().toISOString(),
        },
      ];
    else ratings = all.filter(x => x.id !== existing.id);

    (this.video.sub as any).videoRatings = ratings;
  }

  handleFeedbackRated({
    submissionId,
    type,
  }: {
    submissionId: number;
    type: string;
  }) {
    if (!this.video) return;

    const all: Rating[] = (this.video.feedbackFor as any).videoRatings || [];
    const existing = all.find(x => x.rater === this.userId && x.type === type);
    let ratings: Rating[] = [];

    if (!existing)
      ratings = [
        ...all,
        {
          id: !all.length ? 0 : all[all.length - 1].id + 1,
          submissionId,
          type,
          rater: this.userId,
          createdAt: new Date().toISOString(),
        },
      ];
    else ratings = all.filter(x => x.id !== existing.id);

    (this.video.feedbackFor as any).videoRatings = ratings;
  }

  init() {
    const id = Number(this.$route.params.id);
    if (isNaN(id)) {
      this.notFound = true;
      return;
    }
    this.id = id;
    this.getSubmission();
  }

  @Watch("$route", { immediate: true })
  routeChanged() {
    this.init();
  }
}
